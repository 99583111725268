import axios from 'axios';

const URL = process.env.REACT_APP_BACKEND_URL// Replace with your actual API endpoint


export const handleSearchApi = async (query, setResponse, setLoading) => {
   try {
       const response = await axios.post(URL + "/search", { query: query }, {
           headers: {
               'Content-Type': 'application/json'
           }
       });
       console.log("Response:", response.data);
       if (setResponse) {
           setResponse(response.data);
       }

       setLoading(false)
       return response.data;
   } catch (error) {
       console.error("Error:", error);
       throw error; // Optionally re-throw the error for further handling
   }
};
