import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { RightOutlined } from '@ant-design/icons';
import "./ContentSider.css";

export default function ContentSider({ result, onClose }) {
  const [content, setContent] = useState('');

  useEffect(() => {
    console.log(result.page_url)
    const fetchContent = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_BACKEND_URL + "/proxy", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          
          body: JSON.stringify({
            url: result.page_url,
            headers: {
              'Access-Control-Allow-Methods': '*',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Credentials': 'true'
            }
            
          }),          
        });

        console.log("Response",response)

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const text = await response.text();
        updateImageSources(text);
      } catch (error) {
        console.error('Error fetching content:', error);
      }
    };

    if (result && result.page_url) {
      fetchContent();
    }
  }, [result]);

  // Update image sources to include the base URL before the src
  const updateImageSources = (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");
    const images = doc.querySelectorAll('img');

    images.forEach(img => {
      const src = img.getAttribute('src');
      if (src && !src.startsWith('http')) {
        img.src = `https://techsolutions.cpal.com.au${src.startsWith('/') ? '' : '/'}${src}`;
      }
    });

    setContent(doc.body.innerHTML);
  };

  const variants = {
    hidden: { x: '100%' },
    visible: { x: '0%' },
    exit: { x: '100%' },
  };

  return (
    <>
      <motion.div
        className='fixed top-0 right-0 w-[95%] md:w-[50%] h-full bg-[#f8f8f8] overflow-y-auto p-4 flex flex-col items-center'
        initial="hidden"
        animate="visible"
        exit="exit"
        variants={variants}
        transition={{ type: 'tween', ease: 'easeInOut', duration: 0.5 }}
      >
        <button
          className="absolute left-0 top-1/2 transform -translate-y-1/2 text-black w-12 h-32 bg-gray-200 hover:bg-gray-300 rounded-r-lg"
          onClick={onClose}
        >
          <RightOutlined className='text-lg cursor-pointer' />
        </button>
        {content ? (
          <div className='w-full h-screen overflow-y-auto custom-scrollbar'>
            <h2 className='text-xl w-[93%] m-auto font-medium p-10 rounded-lg bg-[#006e8a] text-white'>{result.heading}</h2>
            <div dangerouslySetInnerHTML={{ __html: content }} className='max-w-full max-h-full p-10' />
          </div>
        ) : (
          <div className="animate-spin inline-block w-8 h-8 border-4 rounded-full border-t-transparent mt-[45%] border-gray-900"></div>
        )}
      </motion.div>
      <style jsx>{`
        .custom-scrollbar::-webkit-scrollbar {
          width: 4px;
        }
        .custom-scrollbar::-webkit-scrollbar-track {
          background: #f1f1f1;
        }
        .custom-scrollbar::-webkit-scrollbar-thumb {
          background: #888;
          border-radius: 5px;
        }
        .custom-scrollbar::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
      `}</style>
    </>
  );
}
