// SearchResults.js
import React, { useState, useEffect } from "react";
import { Skeleton, Tooltip } from "antd";
import {
  ArrowRightOutlined,
  LinkOutlined,
  LeftOutlined,
  InfoCircleOutlined
} from "@ant-design/icons";
import ContentSider from "./ContentSider";
import { motion, AnimatePresence } from "framer-motion";

export default function SearchResults({ responseData, loading }) {
  const [openContent, setOpenContent] = useState(false);
  const [selectedResult, setSelectedResult] = useState(null);

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSource, setSelectedSource] = useState(
    responseData ? responseData.results_by_index[0].index : ""
  );
  const [defaultIndex, setDefaultIndex] = useState(null)
  const indexUrls = {
    xplan: "https://techsolutions.cpal.com.au/xplan-training-library/",
    amansw: "https://www.amansw.com.au",
    uom: "https://www.unimelb.edu.au/"
  };
  const shouldShowIcon = selectedSource == "xplan";

  const handleOpenUrl = (result) => {
    console.log("result", result)
    setSelectedResult(result);
    setOpenContent(true);

  };

  const handleOpenExternalUrl = (result) => {
    if (result && result.page_url) {
      window.open(result.page_url, "_blank");
    } else {
      console.error("No valid URL found in result.");
    }
  };

  const handleClose = () => {
    setOpenContent(false);
  };

  const itemVariants = {
    hidden: { x: -100, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 70,
      },
    },
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };
  const handleSourceClick = (source) => {
    setSelectedSource(source);
    setSelectedCategory('');
  };

  let filteredData = selectedSource
    ? responseData.results_by_index
      .filter((indexData) => indexData.index === selectedSource)
      .map((indexData) => indexData.results)
      .flat()
    : responseData.results_by_index
      .map((indexData) => indexData.results)
      .flat();

  console.log(responseData, filteredData)
  filteredData = selectedCategory
    ? filteredData.filter((item) => item.category === selectedCategory)
    : filteredData;

  const isFilterActive = () => {
    return selectedCategory || (selectedSource !== defaultIndex && defaultIndex !== null);
  };

  const clearAllFilters = () => {
    setSelectedCategory('');
  };

  return (
    <>
      <div className="flex w-full gap-4 mb-10">
        {responseData && filteredData && !loading && (
          <div className="flex flex-col gap-4">
            <div className="w-[88%] flex flex-col items-end">
              <p className="font-poppins text-sm pb-2 font-medium text-primaryContent">
                Showing {filteredData.length} results
              </p>
              <hr className="w-[77%]"></hr>
            </div>
            <div className="flex pt-2 gap-4">
              <div className="filters flex flex-col gap-10">
                <div className="filter">
                  <h4 className="font-poppins text-sm font-bold pb-2 text-primaryFilter">
                    Source
                  </h4>
                  {Object.entries(responseData.results_by_index).map(
                    (indexData, index) => {
                      const indexValue = indexData[1].index; // Get the index value
                      const url = indexUrls[indexValue]; // Get the corresponding URL

                      return (
                        <p
                          key={index}
                          onClick={() => handleSourceClick(indexValue)}
                          className={`font-poppins text-xs pb-2 cursor-pointer hover:underline ${selectedSource === indexValue ? "text-primaryTitle font-bold" : "text-secondaryFilter"}`}
                        >
                          {indexValue}
                          <Tooltip
                            title={
                              <a href={url}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={(event) => event.stopPropagation()}>
                                {url}
                              </a>
                            }
                          >
                            <InfoCircleOutlined style={{ marginLeft: '6px' }} />
                          </Tooltip>
                        </p>
                      );
                    }
                  )}

                </div>

                <div className="filter">
                  <div className="flex space-x-12">
                    <h4 className="font-poppins text-sm font-bold pb-2 text-primaryFilter">
                      Heading

                    </h4>
                    {isFilterActive() && (
                      <span
                        className="font-poppins text-nowrap text-[11px] font-medium text-primaryTitle cursor-pointer"
                        onClick={clearAllFilters}
                      >
                        Clear Filter
                      </span>
                    )}                  </div>
                  {selectedSource &&
                    responseData.results_by_index
                      .filter((indexData) => indexData.index === selectedSource)
                      .map((indexData) =>
                        Object.entries(indexData.category_count).map(
                          ([category, count], index) => (
                            <p
                              key={index}
                              onClick={() => handleCategoryClick(category)}
                              className={`font-poppins text-xs pb-2 cursor-pointer hover:underline ${selectedCategory === category
                                ? "text-primaryTitle font-bold"
                                : "text-secondaryFilter"
                                }`}
                            >
                              {category} ({count})
                            </p>
                          )
                        )
                      )}
                </div>
              </div>
              <div className="cards-container flex flex-col gap-4 w-full">
                {filteredData.map((result, index) => (
                  <motion.div
                    className="border p-4 rounded-lg border-gray-300 w-[85%]"
                    key={index}
                    variants={itemVariants}
                    initial="hidden"
                    animate="visible"
                  >
                    <div className="card">
                      <div className="flex justify-between items-center w-[100%]">
                        <h3 className="text-md font-bold font-poppins text-primaryTitle">
                          {result.category}
                        </h3>
                        {shouldShowIcon && (
                          <ArrowRightOutlined
                            className="text-black cursor-pointer"
                            onClick={() => handleOpenUrl(result)}
                          />
                        )}

                      </div>
                      <h1 className="text-[15px] font-medium font-poppins text-black">
                        {result.question_title}
                      </h1>
                      <p className="text-[13px] font-poppins text-primaryContent mt-2">
                        {result.page_summary}
                      </p>
                      <span
                        className="cursor-pointer mt-2 text-[12px] text-primaryLink font-medium pt-2 hover:underline flex items-center"
                        onClick={() => {
                          selectedSource == "xplan"
                            ? handleOpenUrl(result)
                            : handleOpenExternalUrl(result);
                        }}
                      >
                        <LinkOutlined className="mr-2 text-gray-400" />

                        {result.page_url}
                      </span>
                    </div>
                  </motion.div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
      {!openContent && selectedResult && (
        <button
          className="fixed top-1/2 right-0 transform -translate-y-1/2 text-black w-12 h-32 bg-gray-200 hover:bg-gray-300 rounded-l-lg"
          onClick={() => setOpenContent(true)}
        >
          <LeftOutlined className="text-lg cursor-pointer" />
        </button>
      )}
      <AnimatePresence>
        {openContent && (
          <ContentSider result={selectedResult} onClose={handleClose} />
        )}
      </AnimatePresence>
      <style jsx>{`
        .custom-scrollbar::-webkit-scrollbar {
          width: 4px;
        }
        .custom-scrollbar::-webkit-scrollbar-track {
          background: #f1f1f1;
        }
        .custom-scrollbar::-webkit-scrollbar-thumb {
          background: #888;
          border-radius: 5px;
        }
        .custom-scrollbar::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
      `}</style>
    </>
  );
}
