import axios from "axios";

const URL = process.env.REACT_APP_BACKEND_URL; // Replace with your actual API endpoint
// console.log(URL);

export const handleGetSuggestions = async () => {
  try {
    const response = await axios.get(URL + "/get-suggestions", {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error; // Optionally re-throw the error for further handling
  }
};
