import './App.css';
import LandingPage from './pages/LandingPage';

function App() {
  return (
    <div className="font-poppins">
      <LandingPage/>
    </div>
  );
}

export default App;
