import React, { useState, useEffect, useRef } from "react";
import { handleSearchApi } from "../api/Search/searchApi";
import SearchResults from "./SearchResults";
import { handleGetSuggestions } from "../api/Search/Suggestions";
import { motion } from "framer-motion";
import ScaleLoader from "react-spinners/ScaleLoader";
import elasticSearchIcon from "../assets/Icons/elasticsearch.svg";
import searchIcon from "../assets/Icons/searchicon.png";

export default function SearchField() {
  const [query, setQuery] = useState("");

  const [responseData, setResponseData] = useState("");
  const [loading, setLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const textareaRef = useRef(null);
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1);
  const [isTyping, setIsTyping] = useState(false); // New state variable for typing status
  const [isFocused, setIsFocused] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    //setQuery("");
    setIsTyping(false); // Reset typing status on submit
    let data = await handleSearchApi(query, setResponseData, setLoading);
    setResponseData(data);
  };

  const getResponsiveValue = () => {
    if (window.innerHeight <= 480) {
      return 20; // Value for small screens
    } else if (window.innerHeight <= 768) {
      return 20; // Value for medium screens
    } else {
      return 20; // Value for large screens
    }
  };

  const searchBarVariants = {
    initial: {
      y: 200,
    },
    submitted: {
      y: getResponsiveValue(),
    },
  };

  useEffect(() => {
    const fetchSuggestions = async () => {
      try {
        const data = await handleGetSuggestions();
        console.log("Data", data)
        setSuggestions(data);
      } catch (error) {
        console.error("Error fetching suggestions:", error);
      }
    };

    fetchSuggestions();
  }, []);

  // const handleInputChange = (e) => {
  //   const inputValue = e.target.value;
  //   setQuery(inputValue);

  //   setIsTyping(true); // Set typing status to true
  //   // adjustTextareaHeight(e.target);
  //   if (inputValue.trim()) {
  //     setIsTyping(true);
  //   } else {
  //     setIsTyping(false);
  //   }
  //   // Filter suggestions based on input
  //   if (inputValue.trim()) {
  //     const regex = new RegExp(inputValue.trim(), "i");
  //     console.log("suggestion",suggestions)
  //     const filtered = suggestions.filter((suggestion) =>
  //       regex.test(suggestion)
  //     );
  //     console.log(filtered.slice(0,5))
  //     setFilteredSuggestions(filtered.slice(0, 5)); // Limit to 5 suggestions
  //   } else {
  //     setFilteredSuggestions([]);
  //   }
  // };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setQuery(inputValue);
    setIsTyping(!!inputValue.trim());  // This simplifies the typing status update

    if (inputValue.trim()) {
      const regex = new RegExp(inputValue.trim(), "i");
      const filtered = suggestions.filter((suggestion) =>
        regex.test(suggestion.question_title)
      );
      console.log("Filtered Suggestions: ", filtered.slice(0, 5));
      setFilteredSuggestions(filtered.slice(0, 5));  // Limit to 5 suggestions
    } else {
      setFilteredSuggestions([]);
    }
  };

  const handleKeyDown = (e) => {
    if (filteredSuggestions.length > 0) {
      console.log("filtere", filteredSuggestions)
      switch (e.key) {
        case "ArrowUp":
          e.preventDefault();
          setSelectedSuggestionIndex((prevIndex) =>
            prevIndex > 0 ? prevIndex - 1 : filteredSuggestions.length - 1
          );
          break;
        case "ArrowDown":
          e.preventDefault();
          setSelectedSuggestionIndex((prevIndex) =>
            prevIndex < filteredSuggestions.length - 1 ? prevIndex + 1 : 0
          );
          break;
          case "Enter":
            e.preventDefault();
            if (selectedSuggestionIndex >= 0) {
              const selectedSuggestion = filteredSuggestions[selectedSuggestionIndex];
            
              const displayText = selectedSuggestion.question_title
          
              setQuery(displayText); 
          
              setFilteredSuggestions([]);
              setSelectedSuggestionIndex(-1);
              setIsTyping(false);
            } else {
              handleSubmit();
            }
            break;
          
        default:
          break;
      }
    } else if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSubmit();
    }
  };

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  return (
    <>
      <div className="flex flex-col justify-center items-center">
        <div className="search-bar h-[20%] flex flex-col w-[65%] xl:w-[65%] 2xl:max-w-[90%] text-gray-500">
          <motion.div
            variants={searchBarVariants}
            animate={responseData || loading ? "submitted" : "initial"}
            className="flex flex-col gap-8 items-center"
          >
            <div className="flex justify-center">
              <div className="flex justify-center items-center gap-3">
                <img
                  src={elasticSearchIcon}
                  style={{ width: "40px", height: "40px" }}
                />
                <h1 className="text-black font-semibold text-2xl">
                  Search Assistant
                </h1>
              </div>
            </div>{" "}
            <div
              className={`rounded-lg w-[80%] ${isFocused
                ? "search-bar-focused"
                : "border-2 border-gray-300 outline-none"
                }`}
            >
              <div className="flex items-center justify-center relative p-4">
                <div className="flex w-full">
                  <img
                    src={searchIcon}
                    style={{ width: "20px", height: "20px" }}
                  />
                  <textarea
                    value={query}
                    ref={textareaRef}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    className={`w-full rounded-lg resize-none text-base font-poppins focus:outline-none px-2 h-6 overflow-auto`}
                    placeholder="Search"
                  />
                </div>

                {isTyping && filteredSuggestions.length > 0 && (
                  <ul className="absolute top-16 w-full bg-white border border-gray-300 rounded-lg mt-1">
                    {filteredSuggestions.map((suggestion, index) => {
                      const isTitleValid = suggestion.title !== "No Title";
                      const isQuestionValid = suggestion.question_title !== "No question";
                      const displayText = suggestion.question_title 

                      return (
                        <li
                          key={index}
                          className={`p-3 text-sm cursor-pointer ${index === selectedSuggestionIndex ? "bg-gray-200" : "hover:bg-gray-100"
                            }`}
                          onClick={() => {
                            setQuery(displayText);  
                            setFilteredSuggestions([]);
                            setIsTyping(false); 
                            textareaRef.current.focus();
                          }}
                        >
                          {displayText}
                        </li>
                      );
                    })}
                  </ul>
                )}

              </div>
            </div>
          </motion.div>
        </div>

        <div className="content md:w-[68%] mt-12 h-[20%]">
          {responseData && (
            <SearchResults responseData={responseData} loading={loading} />
          )}
        </div>

        {loading && (
          <div className="h-screen w-full overflow-y-auto fixed top-0 text-gray-400 custom-scrollbar flex items-center justify-center">
            <ScaleLoader color="#E7EAE9" />
          </div>
        )}
      </div>
    </>
  );
}
