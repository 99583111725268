import React from "react";
import SearchField from "../components/SearchField";
import SearchResults from "../components/SearchResults";

export default function LandingPage() {
  return (
    <div>
      <SearchField />
    </div>
  );
}
